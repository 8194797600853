import Link from 'next/link';
import React from 'react';

import { CONTACT, HOME_ROUTE, QEEPER_ADDRESS, SOCAIL } from '@common/constants';
import { cx } from '@emotion/css';

export const Footer = ({ styles }: any) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.text_center}>
          <div className={styles.logo_sec}>
            <Link href="/" aria-label="Link to Home Page">
              <a aria-label="Link to Home Page">
                <img
                  src="/images/QeeperLogo-white.svg"
                  alt=""
                  className={styles.img}
                />
              </a>
            </Link>
          </div>
          <div className={styles.footer_lower}>
            <div className={styles.ftr_contnt_wrpr}>
              <p className={cx(styles.p, styles.addr)}>
                {QEEPER_ADDRESS.line1}
                <br />
                {QEEPER_ADDRESS.line2}
              </p>
              <div className={styles.contect_info}>
                <Link href={`tel:${CONTACT.PHONE}`}>
                  <a className={cx(styles.contact_link, styles.text_white)}>
                    {CONTACT.PHONE}
                  </a>
                </Link>
                |
                <Link href={`mailto:${CONTACT.EMAIL}`}>
                  <a className={cx(styles.contact_link, styles.text_white)}>
                    {CONTACT.EMAIL}
                  </a>
                </Link>
                <div style={{ marginBottom: 10 }} />
                <Link href={HOME_ROUTE.TERMS_CONDITIONS}>
                  <a
                    className={cx(styles.p, styles.addr)}
                    style={{ textDecoration: 'none' }}>
                    Terms & Conditions{' '}
                  </a>
                </Link>
              </div>
              <ul className={styles.social_links}>
                <li>
                  <Link href={SOCAIL.FACEBOOK} aria-label="Facebook Link">
                    <a
                      className={styles.social_link}
                      target="_blank"
                      aria-label="Facebook Link">
                      <i className={styles.icon_fb}></i>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={SOCAIL.TWITTER} aria-label="Twitter Link">
                    <a
                      className={styles.social_link}
                      target="_blank"
                      aria-label="Twitter Link">
                      <i className={styles.icon_twitter}></i>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={SOCAIL.INSTAGRAM} aria-label="Instagram Link">
                    <a
                      className={styles.social_link}
                      target="_blank"
                      aria-label="Instagram Link">
                      <i className={styles.icon_insta}></i>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href={SOCAIL.LINKEDIN} aria-label="Linkedin Link">
                    <a
                      className={styles.social_link}
                      target="_blank"
                      aria-label="Linkedin Link">
                      <i className={styles.icon_linked}></i>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
